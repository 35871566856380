/*
 * @Description: 工程入口文件
 * @Author: Mars
 * @Date: 2023-11-30 14:28:00
 */
import './global.less';
import { AlphaProvider } from '@alpha-ess/store';
import microApp from '@micro-zoe/micro-app';
import structuredClone from '@ungap/structured-clone';
import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from '@/App.tsx';
import { commonTheme } from '@/config/theme';
import '@/i18n';
import { getLanguage } from '@/utils/language';
import { customTheme } from './config/customTheme';

microApp.start(); // 启动微前端服务

// * 增加structuredClone的polyfill，兼容qq浏览器和百度浏览器
if (!('structuredClone' in globalThis)) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  globalThis.structuredClone = structuredClone;
}

const Main = () => {
  useEffect(() => {
    document.getElementById('root')?.setAttribute('class', '');
  }, []);
  return (
    <AlphaProvider initState={{ language: getLanguage(), theme: customTheme ?? commonTheme }}>
      <App />
    </AlphaProvider>
  );
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  // !important 测试终端首页注释掉
  // <React.StrictMode>
  <Main />,
  // </React.StrictMode>,
);

import { ResponseError, getDataCenter, getLocalLanguage, getToken } from '@alpha-ess/utils';
import { IRequestOption } from '@alpha-ess/utils/dist/components/http';
import axios, { CreateAxiosDefaults } from 'axios';
import dayjs from 'dayjs';
import { isDev } from '@/config';

interface CreateRequestOptions {
  /** 开发模式 */
  dev?: boolean;
  /** 不需要鉴权的路由 */
  noAuthPaths?: string[];
  /** axios参数配置 */
  createOptions?: CreateAxiosDefaults<unknown>;
  /** 不包含base路径的接口 */
  excludeBaseUrlList?: string[];
}

/**
 * @Author: Mars
 * @description: 创建请求实例
 * @param {CreateRequestOptions} options
 */
const createRequest = (options: CreateRequestOptions = {}) => {
  const { createOptions = {}, noAuthPaths = [], excludeBaseUrlList = [], dev = false } = options;
  const request = axios.create({
    baseURL: '/api',
    timeout: 50 * 1000,
    headers: {
      platform: 'AK9D8H',
    },
    ...createOptions,
  });

  // 拦截请求
  request.interceptors.request.use(
    (config) => {
      if (getToken() && !noAuthPaths.includes(config?.url || '')) {
        config.headers['Authorization'] = getToken();
      }
      config.headers['language'] = getLocalLanguage();
      config.headers['operationDate'] = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');

      requestDataRemoveSpaces(config.data || config.params, config.method);
      if (excludeBaseUrlList.includes(config.url as string)) {
        config.baseURL = '';
      } else {
        // 数据中心设置
        const dataCenter = getDataCenter();
        if (dataCenter && !dev) {
          config.baseURL = dataCenter + config.baseURL;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  // 响应拦截
  request.interceptors.response.use(
    (rep) => {
      const httpCode: number = rep.status;
      switch (httpCode) {
        case 200:
          if (rep.config.responseType && rep.config.responseType === 'blob' && rep.data instanceof Blob) {
            if (rep.data.type === 'application/json') {
              return { blob: rep.data };
            }
            const blob: Blob = rep.data;
            const contentDisposition = rep.headers['content-disposition'];
            if (contentDisposition) {
              const fileName = window.decodeURI(contentDisposition.split(';')[1].replace(new RegExp('filename='), '').replace(new RegExp('"', 'g'), '')).trim();
              return { blob, fileName };
            } else {
              return { blob };
            }
          } else if (rep.config.responseType == 'arraybuffer') {
            let binary = '';
            const params = rep.data;
            const bytes = new Uint8Array(params);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            return binary;
          } else if ((rep.config as IRequestOption).fullData) {
            return rep.data;
          } else if (rep.data && rep.data.code === '000000') {
            const result = rep.data.data;
            return result;
          } else {
            return Promise.reject({ ...rep.data, msg: rep.data.msg || rep.data.message });
          }
      }
    },
    (error) => {
      const err: ResponseError = { msg: error.message, code: error.response?.status, data: null };
      return Promise.reject(err);
    },
  );

  return request;
};

/**
 * 去掉请求参数中的字符串前后空格
 * @param paramsObj 请求参数
 * @param method 请求方法
 */
function requestDataRemoveSpaces(paramsObj: Record<string, unknown>, method?: string) {
  for (const key in paramsObj) {
    const value = paramsObj[key];
    if (typeof value === 'string') {
      if (key === 'page') {
        paramsObj[key] = Number(value) + 1;
      }
      if (method === 'get') {
        if (value.trim().indexOf('[') > -1 || value.trim().indexOf(']') > -1) {
          paramsObj[key] = encodeURIComponent(value.trim());
        } else {
          paramsObj[key] = value.trim();
        }
      } else {
        paramsObj[key] = value.trim();
      }
    }
    if (typeof value === 'object' && value !== null && !(value instanceof File)) {
      requestDataRemoveSpaces(paramsObj[key] as Record<string, unknown>, method);
    }
  }
}

const requestChengdu = createRequest({
  dev: isDev,
  createOptions: {
    headers: {
      Platform: 'AK9D8H',
      'Client-End': 'Web',
      System: 'ALPHA_CLOUD',
    },
  },
});

export default requestChengdu;

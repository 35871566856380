/*
 * @Description: 语言列表
 * @Author: April
 * @Date: 2024-11-01 10:10:55
 */

export const languageList = [
  {
    label: '中文',
    value: 'zh-CN',
    symbolValue: 'zh',
  },
  {
    label: 'English',
    value: 'en-US',
    symbolValue: 'en',
  },
  {
    label: 'Deutsch',
    value: 'de-DE',
    symbolValue: 'de',
  },
  {
    label: '日本語',
    value: 'ja-JP',
    symbolValue: 'ja',
  },
  {
    label: 'Italiano',
    value: 'it-IT',
    symbolValue: 'it',
  },
  {
    label: 'Nederlands',
    value: 'nl-NL',
    symbolValue: 'nl',
  },
  {
    label: 'España',
    value: 'sp-SP',
    symbolValue: 'sp',
  },
  {
    label: 'Ελληνικά',
    value: 'el-GR',
    symbolValue: 'el',
  },
  {
    label: 'Français',
    value: 'fr-FR',
    symbolValue: 'fr',
  },
  {
    label: 'Polski',
    value: 'pl-PL',
    symbolValue: 'PL',
  },
  {
    label: 'Čeština',
    value: 'cs-CZ',
    symbolValue: 'cs',
  },
];

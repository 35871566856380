/*
 * @Description: 浏览器本地缓存管理
 * @Author: Mars
 * @Date: 2023-11-20 09:58:24
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-10-22 14:49:10
 */

/** 微前端测试页面key */
const MICRO_TEST_KEY = 'MICRO_TEST_HISTORY';
/** 缓存SN */
const LOCAL_SN_KEY = 'localSn';
/** 用户id */
const LOCAL_USER_ID_KEY = 'userId';
/** 站点模式 */
const STATION_MODE_KEY = 'stationMode';
/** 菜单展开 */
const MENU_COLLAPSE_KEY = 'menuCollapse';
/** index.html key */
const INDEX_PAGE_KEY = 'indexPageEtag';
/** ai session id // ! 此ID需要与智能客服子应用中的ID保持一致 */
const SESSION_KEY = 'aiSessionId';
/** 刷新token的令牌 */
const REFRESH_TOKEN = 'refreshToken';
/**
 * @Author: Mars
 * @description: 清除智能客服id
 */
export const clearSessionId = () => localStorage.setItem(SESSION_KEY, '');

/**
 * @Author: Mars
 * @description: 获取本地存储的选中sn
 * @return {*} sn
 */
export const getLocalSn = () => localStorage.getItem(LOCAL_SN_KEY) || '';

/**
 * @Author: Mars
 * @description: 设置本地存储的选中sn
 * @param {string} sn
 */
export const setLocalSn = (sn: string) => localStorage.setItem(LOCAL_SN_KEY, sn);

/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 获取本地存储的userId
 */
export const getLocalUserId = () => localStorage.getItem(LOCAL_USER_ID_KEY) || '';
/**
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @description: 设置本地存储的userId
 * @param {string} userId
 */
export const setLocalUserId = (userId: string) => localStorage.setItem(LOCAL_USER_ID_KEY, userId);
/**
 * @Author: YuKi
 * @description: 获取刷新Token
 * @return {*} token
 */
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN) || '';

/**
 * @Author: YuKi
 * @description: 设置刷新Token
 * @param {string} token
 */
export const setRefreshToken = (token: string) => localStorage.setItem(REFRESH_TOKEN, token);
/**
 * @Author: YuKi
 * @description: 设置刷新Token
 * @param {string} token
 */
export const removeRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN);
/**
 * @Author: Mars
 * @description: 获取菜单展开
 * @return {*}
 */
export const getMenuCollapse = () => {
  const value = localStorage.getItem(MENU_COLLAPSE_KEY);
  return value ? value === 'true' : true;
};

/**
 * @Author: Mars
 * @description: 设置菜单展开
 * @param {boolean} mode 开关
 * @return {*}
 */
export const setMenuCollapse = (mode: boolean) => localStorage.setItem(MENU_COLLAPSE_KEY, mode.toString());

/**
 * @Author: Mars
 * @description: 获取站点模式
 * @return {*}
 */
export const getStationMode = () => {
  const value = localStorage.getItem(STATION_MODE_KEY);
  return value ? value === 'true' : false;
};

/**
 * @Author: Mars
 * @description: 设置站点模式
 * @param {string} mode 模式
 */
export const setStationMode = (mode: boolean) => localStorage.setItem(STATION_MODE_KEY, mode.toString());

/**
 * @Author: Mars
 * @description: 获取微应用测试历史地址
 * @param {*} string
 * @return {*}
 */
export const getMicroTestHistory = (): string[] => {
  const historyText = localStorage.getItem(MICRO_TEST_KEY);
  if (historyText) {
    return historyText.split('|');
  } else {
    return [];
  }
};

/**
 * @Author: Mars
 * @description: 添加微应用测试地址
 * @param {string} url
 * @return {*}
 */
export const addMicroTestHistory = (url: string) => {
  const history = getMicroTestHistory();
  if (!history.includes(url)) {
    history.push(url);
  }
  localStorage.setItem(MICRO_TEST_KEY, history.join('|'));
};

/**
 * @Author: Mars
 * @description: 删除微应用测试地址
 * @param {string} url
 * @return {*}
 */
export const deleteMicroTestHistory = (url: string) => {
  let history = getMicroTestHistory();
  history = history.filter((it) => it !== url);
  localStorage.setItem(MICRO_TEST_KEY, history.join('|'));
};

/**
 * @Author: Mars
 * @description: 设置应用etag
 * @param {string} url 应用地址
 * @param {string} etag etag标识
 * @return {*}
 */
export const addPageEtag = (url: string, etag: string) => {
  const pageStr = localStorage.getItem(INDEX_PAGE_KEY);
  if (pageStr) {
    try {
      const pageData = JSON.parse(pageStr);
      pageData[url] = etag;
      localStorage.setItem(INDEX_PAGE_KEY, JSON.stringify(pageData));
    } catch (e) {
      const pageData = {
        [url]: etag,
      };
      localStorage.setItem(INDEX_PAGE_KEY, JSON.stringify(pageData));
    }
  } else {
    const pageData = {
      [url]: etag,
    };
    localStorage.setItem(INDEX_PAGE_KEY, JSON.stringify(pageData));
  }
};

/**
 * @Author: Mars
 * @description: 获取应用etag
 * @param {string} url 应用地址
 * @return {*}
 */
export const getPageEtag = (url: string) => {
  const dataStr = localStorage.getItem(INDEX_PAGE_KEY);
  if (dataStr) {
    try {
      const data = JSON.parse(dataStr);
      return (data[url] as string) || null;
    } catch (e) {
      return null;
    }
  }
};

/**
 * @Author: Mars
 * @description: 清空应用etag
 * @return {*}
 */
export const clearIndexPageEtag = () => {
  localStorage.setItem(INDEX_PAGE_KEY, '');
};

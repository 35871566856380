/*
 * @Description: 消息状态
 * @Author: Mars
 * @Date: 2023-12-28 13:44:47
 * @LastEditors: Mars
 * @LastEditTime: 2023-12-28 15:41:27
 */
import { atom } from 'recoil';

/** 未读消息数量 */
export const unreadMsgCountState = atom({ key: 'unreadMsgCountState', default: 0 });
